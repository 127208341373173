import { isBrowser } from "./constants";

export const scrollToTop = (topPosition = 0) => {
  if (isBrowser) {
    const startY = window.scrollY || document.documentElement.scrollTop;
    const distance = topPosition - startY;
    let startTime = null;

    const animation = currentTime => {
      if (!startTime) startTime = currentTime;
      const progress = Math.min((currentTime - startTime) / 500, 1); // 500ms duration
      const easeInOutQuad =
        progress < 0.5 ? 2 * progress * progress : 1 - Math.pow(-2 * progress + 2, 2) / 2;

      window.scrollTo(0, startY + distance * easeInOutQuad);

      if (progress < 1) {
        requestAnimationFrame(animation);
      }
    };

    requestAnimationFrame(animation);
  }
};

export const scrollToViewPosition = elementId => {
  if (isBrowser) {
    const elementRef = document.getElementById(elementId);

    if (elementRef) {
      return elementRef.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }
  }
};

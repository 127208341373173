import React from "react";

import { isBrowser } from "../../utils/constants";
import { Icon } from "../../globals/icons";
import { Box, Heading, Flex, Text, Image } from "../../globals/UIKit";
import { StyledJobListWrapper } from "./StyledCareersPage";
import { StyledBody1, StyledDesc1, StyledDesc2 } from "../shared/StyledTypography";

const getJobLinkId = jobLinkString => {
  const jobIdRegex = /jobId=([^&]+)/;

  const match = jobLinkString.match(jobIdRegex);

  if (match) {
    const jobId = match[1];

    return jobId;
  } else {
    return "";
  }
};

const JobListCard = ({ jobObj }) => {
  if (isBrowser && jobObj) {
    const body = jobObj.description;

    const temp = document.createElement("div");

    temp.innerHTML = body;

    const sanitizedDesc = temp.textContent || temp.innerText;

    const trimmedJobDesc =
      sanitizedDesc.length > 110 ? `${sanitizedDesc.substring(0, 110)}...` : sanitizedDesc;

    const jobLinkId = getJobLinkId(jobObj?.applicant_apply_link);

    return (
      <StyledJobListWrapper to={`/careers?jobId=${jobLinkId}`}>
        <div>
          <StyledBody1 variant="neutral_900" fontWeight="font_600" paddingBottom="pb16">
            {jobObj.title}
          </StyledBody1>
          <StyledDesc1 className="textEllipsis" variant="neutral_900">
            {trimmedJobDesc}
          </StyledDesc1>
        </div>
        <div className="flexRightCenter">
          <StyledDesc2 variant="neutral_900">
            {jobObj?.remote ? "Remote" : `${jobObj.branch.city}, ${jobObj.branch.state}`}
            <br />
            {jobObj?.type === "full_time" ? "Full Time" : ""}
          </StyledDesc2>
          <Icon variant="chevron_right" color="blue_600" size="md" />
        </div>
      </StyledJobListWrapper>
    );
  }

  return null;
};

export const JobsList = props => {
  // In case of no jobs found
  if (props.currentActiveDepartment === props.departmentName) {
    if (props.jobsListArr.length === 0) {
      return (
        <Flex justifyContent="center">
          <Box textAlign="center">
            <Image
              src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1679332745/main-web/career_icon_x4f5b8.svg"
              alt="career-icon"
              minWidth="36px"
              minHeight="34px"
              width="36px"
              height="34px"
              mb="56px"
            />
            <Text color="#111b29" fontSize="20px" lineHeight="1.5" fontWeight="600" pb="8px">
              No jobs found
            </Text>
            <Text color="#111b29" fontSize="14px" lineHeight="1.5" fontWeight="400" pb="32px">
              Oops, you have no jobs that match the filter conditions.
            </Text>
            <Text color="#111b29" fontSize="14px" lineHeight="1.5" fontWeight="400">
              Try refining your search.
            </Text>
          </Box>
        </Flex>
      );
    }
  }

  if (props?.jobsListArr) {
    const filterJobsDepartmentWiseArr = props.jobsListArr.filter(
      jobObj => jobObj.department.name === props.departmentName
    );

    if (filterJobsDepartmentWiseArr && !!filterJobsDepartmentWiseArr.length) {
      const getNoOfOpenRoles = () => {
        if (filterJobsDepartmentWiseArr.length === 1) {
          return `1 Open Role`;
        }

        return `${filterJobsDepartmentWiseArr.length} Open Roles`;
      };

      return (
        <Box mb="60px">
          <Flex mb="55px" justifyContent="space-between">
            <Heading as="h3" fontSize="18px" lineHeight="1.5" color="#111b29" fontWeight="600">
              {props.departmentName}
            </Heading>
            <Flex
              height="26px"
              width="101px"
              borderRadius="4px"
              border=".5px solid #5c94c8"
              justifyContent="center"
            >
              <Text color="#5c94c8" fontSize="13px" fontWeight="500" bg="transparent">
                {getNoOfOpenRoles()}
              </Text>
            </Flex>
          </Flex>
          {filterJobsDepartmentWiseArr.map(jobObj => (
            <JobListCard jobObj={jobObj} key={jobObj.id} />
          ))}
        </Box>
      );
    }
  }

  return null;
};

import React from "react";

import { CareersHomePage } from ".";
import Layout from "../layout";
import Seo from "../seo";

const ogImage = {
  ogImage:
    "https://res.cloudinary.com/hasura-cms-uploads/image/upload/v1727188851/home_og_5bcffcf84b_bc13538b52.png",
};

const canonicalUrl = "https://hasura.io/careers/";

const Careers = props => {
  const freshteamJobsData = props?.pageContext?.freshteamData;

  const searchParams = new URLSearchParams(props.location?.search);

  const searchJobId = searchParams.get("jobId");

  return (
    <Layout location={props.location}>
      <Seo
        title="Work with us | Universal data access layer for next-gen apps and AI"
        description="The fastest and simplest way to build, run, govern, and evolve a high-quality API layer on all your data. Work with us!"
        meta={ogImage}
        canonicalLink={canonicalUrl}
      />
      <CareersHomePage
        location={props.location}
        searchJobId={searchJobId}
        freshteamJobsData={freshteamJobsData}
      />
    </Layout>
  );
};

export default Careers;

import React, { useEffect, useState } from "react";

import { CareersLanding } from "./LandingBanner";
import { HasuraValues } from "./HasuraValues";
import { StyledCareersPage } from "./StyledCareersPage";
import { HasuraPerks } from "./HasuraPerks";
import { TeamSlider } from "./TeamSlider";
import { TeamMemberQuotes } from "./TeamMemberQuotes";
import { FreshDeskJobs } from "./FreshdeskJobs";
import { JobDetailsPage } from "./JobDetailsPage";
import { CareersNetworkLoader } from "./CareersNetworkLoader";
import "./styles.scss";
import { scrollToTop } from "../../utils/helpers";

export const CareersHomePage = ({ location, searchJobId, freshteamJobsData }) => {
  const [currentPageData, updateCurrentPageData] = useState([]);

  const jobsDataArr = freshteamJobsData;

  useEffect(() => {
    if (searchJobId) {
      const individualJobPageData = jobsDataArr.filter(
        jobObj =>
          jobObj.applicant_apply_link.toString() ===
          `https://hasura.io/careers/?jobId=${searchJobId}`
      );

      updateCurrentPageData(individualJobPageData);

      scrollToTop();
    }
  }, [searchJobId]);

  // Indiv Job Page ******** //
  if (searchJobId && currentPageData.length > 0) {
    return (
      <StyledCareersPage>
        <JobDetailsPage jobDetailsData={currentPageData[0]} location={location} />
      </StyledCareersPage>
    );
  }

  if (jobsDataArr) {
    return (
      <StyledCareersPage>
        <CareersLanding />
        <HasuraValues />
        <TeamSlider />
        <TeamMemberQuotes />
        <HasuraPerks />
        <FreshDeskJobs location={location} jobsDataArr={jobsDataArr} />
      </StyledCareersPage>
    );
  }

  return <CareersNetworkLoader />;
};
